<template>
  <div class="wrap">
    <banner></banner>
    <div class="content_area content">
      <div class=" title one">
        {{ $t('Recommendedexhi') }}
      </div>
      <div style="height: 191px;margin-bottom: 40px;" v-loading="loading">
        <div class="recommend" >
          <div v-for="(item,index) in bigImgData" :key="index" @click="open(item,1)">
            <el-image
                style="width: 635px; height: 191px;cursor: pointer;"
                :src="LOCALE === 'en'?item.cover_url_en:item.cover_url_zh"
                fit="scale-down
"></el-image>
          </div>
        </div>
      </div>
      <div class="title two">
        {{ $t('nessPavili') }}
      </div>
      <div style="height: 760px" v-loading="loading">
        <div class="business">
          <div class="item" v-for="(item,index) in imgData" :key="index" @click="open(item,2)">
            <div class="img">
              <el-image
                  style="width: 240px; height: 240px;border-radius:50%;"
                  :src="LOCALE === 'en'?item.cover_url_en:item.cover_url_zh"
                  fit="scale-down
"></el-image>
            </div>
            <div class="name">
              {{ item.name_en|priorFormat(item.name_zh,LOCALE) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        :title="$t('tipsTitle')"
        :visible.sync="dialogVisible"
        width="300px"
        :close-on-click-modal="true"
    >
      <div class="tc openDialog">{{ $t("pavilionisundercon") }}</div>
    </el-dialog>
  </div>
</template>

<script>
import banner from "~exp/components/common/banner";

export default {
  name: "pavilion",
  components: {
    banner
  },
  data() {
    return {
      dialogVisible: false,
      bigImgData:[],
      imgData: [],
      loading: false
    }
  },
  created() {
    this.getExhibitionList()
    this.$log({
      type:2,
      id:'Hall',
      topic:'Hall',
     
    })
  },
  methods: {
    open(item,type){
      if(type == 1){
        this.$log({
          type:2,
          id:'Hall.recommended',
          topic:'Hall.recommended',
          content:JSON.stringify({
              pavilionId:item.id
          })
        })
      }
      if(type == 2){
        this.$log({
          type:2,
          id:'Hall.business',
          topic:'Hall.business',
          content:JSON.stringify({
              pavilionId:item.id
          })
        })
      }
      this.$router.push({
        path: "/boothList",
        query: {
          pavilion_id: item.id,
        }
      })
      // this.dialogVisible=true
    },
    async getExhibitionList() {
      this.loading = true
      let params = {};
      params.meeting_id = this.MEETING_ID;
      params.is_open = 1;
      let res = await this.$store.dispatch("booth/getExhibitionByMeenting", params);
      this.loading = false
      if (res.success) {
        this.bigImgData = res.data.filter((item) => {
          return item.type == 2;
        });
        this.imgData = res.data.filter((item) => {
          return item.type == 1;
        });
      }
    },
  }
}
</script>

<style scoped lang="less">
.wrap {
  .content {
    padding-top: 32px;

    .title {
      text-align: center;
      font-size: 24px;
      font-weight: 800;
      color: #333333;
      margin-bottom: 24px;
    }

    .recommend {
      display: flex;
      justify-content: space-between;
    }

    .business {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .item {
        width: 240px;
        margin-right: 93px;
        margin-bottom: 40px;
        cursor: pointer;

        &:nth-child(4n) {
          margin-right: 0;
        }

        .img {
          width: 240px;
          height: 240px;
          margin-bottom: 15px;
        }

        .name {
          font-size: 18px;
          font-weight: 800;
          color: #333333;
          text-align: center;
        }
      }
    }
  }
}

.openDialog {
  height: 100px;
  line-height: 100px;
  text-align: center;
}
</style>
